import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import "../../assets/styles.scss";
import Main from "../../layout/layout";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Masonry from "react-masonry-css";
import ImageViewer from "../../layout/imageViewer";
import { Helmet } from "react-helmet";
import {
  Trans,
  useTranslation,
} from "gatsby-plugin-react-i18next";
import SEO from "../../layout/seo";

// markup
const Singles = ({ location, data }) => {
  const { t } = useTranslation();

  const categories = data.allContentfulSinglePhotos.edges;
  const [category, setCategory] = useState("Singles");
  const [filteredImages, setFilteredImages] = useState([]);
  const [viewerVisible, setViewerVisible] = useState(false);
  const [photoVisible, setPhotoVisible] = useState(false);
  const [indexPhotoVisble, setIndexPhotoVisible] = useState(0);

  useEffect(() => {
    let imgs = [];

    for (let cat of categories) {
      for (let img of cat.node.photos) {
        if (cat.node.name === category || category === "Singles") {
          console.log(img);
          imgs.push({ category: cat.node.name, image: img });
        }
      }
    }

    const shuffledArray = imgs.sort(function (a, b) {
      return new Date(b.image.createdAt) - new Date(a.image.createdAt);
    });

    setFilteredImages(shuffledArray);
  }, [category]);

  const changeCategory = (cat) => {
    if (category === cat) {
      setCategory("Singles");
    } else {
      setCategory(cat);
    }
  };

  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    700: 2,
    500: 1,
  };

  const toggleImageViewer = () => {
    if (viewerVisible === false) {
      document.body.classList.add("viewer-visibile");
    } else {
      document.body.classList.remove("viewer-visibile");
    }
    setViewerVisible(!viewerVisible);
  };

  const previous = () => {
    let key = indexPhotoVisble;
    if (key > 0) {
      key = indexPhotoVisble - 1;
    }
    setIndexPhotoVisible(key);
    setPhotoVisible(filteredImages[key].image);
  };

  const next = () => {
    let key = indexPhotoVisble;
    if (key < filteredImages.length - 1) {
      key = indexPhotoVisble + 1;
    }
    setIndexPhotoVisible(key);
    setPhotoVisible(filteredImages[key].image);
  };

  const openPhoto = (photo, key) => {
    setPhotoVisible(photo);
    setIndexPhotoVisible(key);
    toggleImageViewer();
  };

  return (
    <main>
      <SEO title={t("Photo singles")} description={t("Description photo singles")}>
      </SEO>
      <Main location={location}>
        {viewerVisible ? (
          <ImageViewer
            close={toggleImageViewer}
            previous={indexPhotoVisble === 0 ? false : previous}
            next={indexPhotoVisble === filteredImages.length - 1 ? false : next}
            image={photoVisible}
          ></ImageViewer>
        ) : (
          <></>
        )}
        <div className="main-container">
          <div style={{ marginBottom: "2rem" }} className="title-page">
            <Trans>Photo singles</Trans>
            <div className="hr"></div>
          </div>
          {/* <div className="singles-selector">
            {categories.map((cat) => {
              return (
                <span>
                  {" "}
                  <a
                    className={category === cat.node.name ? "link-active" : ""}
                    onClick={() => changeCategory(cat.node.name)}
                  >
                    {cat.node.name}
                  </a>{" "}
                  /{" "}
                </span>
              );
            })}
          </div> */}
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {filteredImages.map((img, key) => {
              let image = getImage(img.image);

              return (
                <div onClick={() => openPhoto(img.image, key)}>
                  <GatsbyImage
                    objectFit="cover"
                    style={{ cursor: "pointer" }}
                    image={image}
                    alt={""}
                  />
                </div>
              );
            })}
          </Masonry>
        </div>
      </Main>
    </main>
  );
};

export const pageQuery = graphql`
  query langSinglesPho($language: String!) {
    allContentfulSinglePhotos {
      edges {
        node {
          id
          name
          photos {
            title
            createdAt
            gatsbyImageData(
              width: 1500
              placeholder: DOMINANT_COLOR
              formats: [AUTO, AVIF, WEBP]
            )
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Singles;
